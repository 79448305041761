import React, { useState, useRef, useEffect, useContext } from "react";
import dogecoin from "../assets/crypto/dogecoin.png";
import AppContext from "../context/AppContex";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import ArbitrageList from "../components/ArbitrageList";
import Countdown from "../components/Countdown";
import ArbitrageSubmit from "../components/ArbitrageSubmit";
export default function Bot() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [userData, setUserData] = useState([]);
  const { updateLoading, loading, modal, state, modalBuy } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    // Comprobamos si la URL contiene #down
    if (location.hash === "#down") {
      const downElement = document.getElementById("down");
      if (downElement) {
        downElement.scrollIntoView({ behavior: "smooth" }); // Desplazamos suavemente hacia el elemento
      }
    }
  }, [location.hash]); // Ejecutar el efecto cuando la ubicación cambie

  const truncateString = (str) => {
    if (str.length <= 20) {
      return str;
    } else {
      return str.slice(0, 20) + '...';
    }
  };

  useEffect(() => {
    getTrx();
  }, [page, limit]);

  useEffect(() => {
    if (state?.modal.buy) {
      getTrx();
    }
  }, [state?.modal.buy]);


  const getTrx = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/list-trx?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status !== false) {
          setData(response?.data?.items); // Guardamos los datos mapeados
          let userDat = response?.data?.user;
          setUserData(userDat);
          if (parseInt(userDat?.arbitrage_type) == 0) {
            modalBuy(true);
          }
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };




  return (
    <>
      <h1 className="title-main">{t("_BOTTRADING._BOTTRADING")}</h1>
      <div>
        <div className="d-lg-grid gap-3 mt-4 d-sm-flex" style={{ gridTemplateColumns: '50% 50%' }}>
          <ArbitrageList />
          <div className="blue-box px-3 px-md-4 py-3 py-lg-4" style={{ marginTop: '0px' }}>
            <div>
              <h5 className="mb-0">{t("_BOTTRADING._TABLE2")}</h5>
              <p className="text-green f14-w400 fw-normal mt-3">{t("_BOTTRADING._TABLE2_A")} <span className="f14-w400 fw-bold mx-4">Available in Automatic</span></p>
              <div className="line-gradient bot"></div>
              <div>
                <div className="w-100 fs-12 mt-3">
                  <label htmlFor="withdrawalAddress" className="text-green" >Proximo retiro:</label>
                  <Countdown targetDate={userData?.date} />
                </div>
                <ArbitrageSubmit targetDate={userData?.date} />
              </div>
              <div className="line-gradient bot"></div>
              <p className="text-green f14-w400 fw-normal mt-3 mb-0">{t("_BOTTRADING._TABLE2_D")}</p>
              <div className="d-grid gap-2" style={{ gridTemplateColumns: 'calc(50% - 8px) 1px calc(50% - 8px)' }}>
                <div>
                  <p className="f14-w400 fw-bold m-0">CEX - DEX</p>
                  <p className="fs-12 fw-bold m-0 d-flex justify-content-between">{t("_BOTTRADING._TABLE2_D_1")} <span className="fs-12 text-red fw-bold">0%</span></p>
                  <p className="fs-12 fw-bold m-0 d-flex justify-content-between">{t("_BOTTRADING._TABLE2_D_2")} <span className="fs-12 text-red fw-bold">0.000</span></p>
                  <p className="fs-12 fw-bold m-0 d-flex justify-content-between">{t("_BOTTRADING._TABLE2_D_2")}</p>
                  <p className="fs-12 fw-bold m-0 d-flex justify-content-between"> (USDT)<span className="fs-12 text-red fw-bold">$ 0.00</span></p>
                </div>
                <div className="line-gradient bot-vertical" style={{ height: '100%', width: '1px' }}></div>
                <div className="d-flex flex-column justify-content-between">
                  <p className="fs-12 fw-bold m-0 d-flex justify-content-between">{t("_BOTTRADING._TABLE2_D_3")} <span className="fs-12  fw-bold">14</span></p>
                  <p className="fs-12 fw-bold m-0 d-flex justify-content-between">{t("_BOTTRADING._TABLE2_D_4")} <span className=" fs-12 fw-bold">$7.000</span></p>
                  <p className="fs-12 fw-bold m-0 d-flex justify-content-between">{t("_BOTTRADING._TABLE2_D_5")} <span className="fs-12  fw-bold">$873.5201,00</span></p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="mainchart mt-4 px-3 px-md-4 py-3 py-lg-4 text-white">
          <div>
            <h5 className="mb-0">{t("_BOTTRADING._TABLE3")}</h5>
          </div>
          <div className="recent-contact pb-2 pt-3" >
            <table>
              <thead>
                <tr className="border-b2">
                  <th>{t("_BOTTRADING._TABLE3_A")}</th>
                  <th>{t("_BOTTRADING._TABLE3_B")}</th>
                  <th>{t("_BOTTRADING._TABLE3_C")}</th>
                  <th>{t("_BOTTRADING._TABLE3_D")}</th>
                </tr>
              </thead>

              <tbody >
                {data && data.map((data, index) => (
                  <tr className="border-b2" key={index}>
                    <td>{data?.created}</td>
                    <td> <a href={'https://testnet.bscscan.com/tx/' + data?.trx} target="_blank">{truncateString(data?.trx)} </a> </td>
                    <td>USDT ${data?.amount}</td>
                    <td><span className="green-tag">{t("_STATUSES._COMPLETED")}</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div id="down"></div>
          </div>
        </div>
      </div>
    </>
  );
}
