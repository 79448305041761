import React, { useState, useEffect, useContext } from 'react';
import { useTranslation, Trans } from "react-i18next";
import AppContext from "../context/AppContex";
import { useLocation } from "react-router-dom";
import PackageCard from '../components/PackageCard';
import barras from "../assets/svg/barras.svg";
import toast from "react-hot-toast";

export default function Packages() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const [packagesList, setPackagesList] = useState([]);
  const [packageTotal, setPackageTotal] = useState(0);
  const [userData, setUserData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const { updateLoading, loading } = useContext(AppContext);
  const location = useLocation();


  useEffect(() => {
    // Comprobamos si la URL contiene #down
    if (location.hash === "#active") {
      toggleVisibility(true)
    }
  }, [location.hash]); // Ejecutar el efecto cuando la ubicación cambie

  useEffect(() => {
    getPackages();
  }, []);


  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };


  const toggleTab = () => {
    setIsVisible(!isVisible);
    getPackages();
  };


  const getPackages = (data) => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/packages?limit=100`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response?.status !== false) {
          setPackagesList(response?.data?.items)
          const filteredPackages = response?.data?.items.filter((item) => item.completed === "0" && item?.status === "1");
          setPackageTotal(filteredPackages.length)
          setUserData(response?.data?.user)
          const data = response?.data?.user;
          if (data && data.package_value_maximun && data.package_value_current) {
            const max = data.package_value_maximun;
            const current = data.package_value_current;
            const calculatedPercentage = (current / max) * 100;
            setPercentage(calculatedPercentage);
          }
        }
        updateLoading(false);
      })
      .catch((error) => {
        toast.error("No existe patrocinador, vuelve a intentarlo");
        updateLoading(false);
        console.log(error);
      });
  }

  return (
    <div className='pb-4'>
      <h1 className="title-main">
        {isVisible ? t("_PACKAGES._PACKAGES") : t("_PACKAGES._MYPACKAGES")}
        <div className="title-main-button">
          <button className="darkblue" onClick={toggleVisibility}>
            {isVisible ? t("_PACKAGES._MYPACKAGES") : t("_BUTTONS._BACK")}
          </button>
        </div>
      </h1>
      {parseInt(userData?.balance_wavees) > 0 && (
        <>
          <button type="button" className="btn btn-primary position-relative my-2">
            Tienes un Airdrop  {userData?.balance_wavees} 🌊 Wavees y puedes usarlo para comprar un paquete
            <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
              <span className="visually-hidden">New alerts</span>
            </span>
          </button>
        </>
      )}
      {isVisible &&
        <div className="packages-grid">
          <PackageCard precio="50" porcentaje="200%" ganancia="$100" planes="6" nombre="W1" color="#29C7E2" packagetype={1} toggleTab={toggleTab} unilevel={"5"} airdrop={25} />
          <PackageCard precio="100" porcentaje="200%" ganancia="$200" planes="4" nombre="W2" color="#00afcd" packagetype={2} toggleTab={toggleTab} unilevel={"7"} airdrop={500} />
          <PackageCard precio="500" porcentaje="200%" ganancia="$1000" planes="3" nombre="W3" color="#29B6ED" packagetype={3} toggleTab={toggleTab} unilevel={"10"} airdrop={2500} />
          <PackageCard precio="1000" porcentaje="300%" ganancia="$3000" planes="3" nombre="W4" color="#1998F9" packagetype={4} toggleTab={toggleTab} unilevel={"13"} airdrop={5000} />
          <PackageCard precio="3000" porcentaje="300%" ganancia="$9000" planes="2" nombre="W5" color="#1E81E2" packagetype={5} toggleTab={toggleTab} unilevel={"15"} airdrop={15000} />
          <PackageCard precio="5000" porcentaje="300%" ganancia="$15000" planes="2" nombre="W6" color="#2E3192" packagetype={6} toggleTab={toggleTab} unilevel={"18"} airdrop={25000} />
          <PackageCard precio="10000" porcentaje="300%" ganancia="$30000" planes="2" nombre="W7" color="#0C3182" packagetype={7} toggleTab={toggleTab} unilevel={"21"} airdrop={50000} />
          <PackageCard precio="15000" porcentaje="350%" ganancia="$52500" planes="2" nombre="W8" color="#0C1138" packagetype={8} toggleTab={toggleTab} unilevel={"22+1"} airdrop={75000} />
        </div>
      }
      {!isVisible &&
        <>
          <div className="packages-grid">
            {packagesList.map((opportunity, index) => (
              <PackageCard precio={`$${opportunity?.price} `} porcentaje="200%" ganancia={opportunity?.total} planes="6" nombre={`W${opportunity?.packages_type} `} color={`${opportunity?.status === "1" ? "#29C7E2" : "#686868"}`} balance={opportunity?.balance} progressValue={(opportunity?.balance / opportunity?.total) * 100} giro={true} status={opportunity?.status} completed={opportunity?.completed} />
            ))}
          </div>
          <div className="packages-grid mt-4 mb-4">
            <div className="full-column">
              <div className="grid">
                <div>
                  <p>{t("_PACKAGES._PACKAGES")}</p>
                  <h3>Packages Actives {packageTotal}</h3>
                </div>
                <div>
                  <img className="" src={barras} alt="" />
                </div>
              </div>

              <div className='flex-between'>
                <p className="f14-w400 green">Start</p>
                <p className="f14-w400 green">Finish {userData?.package_value_maximun || 0} 🌊</p>
              </div>
              <progress id="initialProgress" max="100" value={percentage} style={{ width: "100%" }} className="pink"></progress>
              <p className="f14-w400 ta-right">+{percentage.toFixed(2)}%</p>
            </div>
          </div>
        </>
      }
    </div>
  )
}
