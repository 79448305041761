import React, { useState, useContext } from "react";
import AppContext from "../context/AppContex";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const BuyModal = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { modalBackground, updateLoading, modalBuy, state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [type, setType] = useState(0);


  const confirmType = (id) => {
    setType(id)
    setStep(2)
  }

  const back = () => {
    setStep(1)
  }

  const closed = () => {
    modalBuy(false);
    modalBackground(false);
  };


  const handleConfigure = () => {
    const formData = new FormData();
    formData.append("type", type);
    fetch(`${process.env.REACT_APP_BACKEND}/update-type-bot`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status === false) {
          toast.error("Vuelve a intentarlo.");
        } else {
          toast.success("Se configuro correctamente tu bot de trading");
        }
        modalBuy(false);
        modalBackground(false);
        updateLoading(false);
      })
      .catch((error) => {
        updateLoading(false);
        console.log(error);
        toast.error("Vuelve a intentarlo");
      });
  };

  return (
    <>
      <div className="modal fade show" tabIndex="-1" aria-modal="true" role="dialog"
        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="fullscreenModalLabel">Modo de Arbitrage Bot</h5>
              <button type="button" className="btn" aria-label="Close" onClick={() => closed()}><i className="bi bi-x-lg" style={{ color: 'white' }}></i></button>
            </div>
            <div className="modal-body">
              {step === 1 && (
                <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
                  <div className="d-flex flex-column justify-content-center text-center w-100 w-md-50" id="automatic" onClick={() => confirmType(1)}>
                    <i className="bi bi-cpu" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>
                    <h5>Automático</h5>
                    <p>Recibe ganancias de forma automática.</p>
                  </div>
                  <div className="d-flex flex-column justify-content-center text-center w-100 w-md-50" id="manualy" onClick={() => confirmType(2)}>
                    <i className="bi bi-hand-index" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>
                    <h5>Manual</h5>
                    <p>Retira tus ganancias diarias al completar el horario.</p>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="d-flex flex-column ">
                  {type == 1 && (
                    <div className="text-center">
                      <i className="bi bi-cpu" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>
                      <h5>Automático</h5>
                      <p>Con la opción automática, recibirás tus ganancias cada 24 horas directamente en tu wallet. Nosotros gestionamos todas las operaciones por ti, aplicando una comisión del 2% que cubre los costos de transacción y la automatización del proceso.</p>
                    </div>
                  )}
                  {type == 2 && (
                    <div className="text-center">
                      <i className="bi bi-hand-index" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>
                      <h5>Manual</h5>
                      <p>Con la opción manual, deberás realizar el retiro de tus ganancias de forma manual cada 24 horas. En este caso, asumirás los costos de retiro, los cuales se procesarán mediante BNB.</p>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="modal-footer">
              {step === 1 && (
                <button type="button" className="btn btn-secondary" onClick={() => closed()}>
                  Configurar luego...
                </button>
              )}
              {step === 2 && (
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => back()}>
                    Regresar
                  </button>
                  <button type="button" className="button-generic lightblue" onClick={() => handleConfigure()}>
                    Configurar
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyModal;
