import React, { useState, useContext, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import AppContext from "../context/AppContex";
import toast from "react-hot-toast";
import {
  type BaseError,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { parseEther, parseUnits, isAddress } from "viem";
import { abi } from "../abi/erc20";
import { useAccount } from "wagmi";

export default function PackageCard(props: any) {
  const { address } = useAccount();
  const { t } = useTranslation();
  const { loading, updateLoading } = useContext(AppContext);
  const {
    nombre,
    precio,
    porcentaje,
    ganancia,
    planes,
    color,
    progressValue,
    giro,
    balance,
    packagetype,
    toggleTab,
    status,
    completed,
    airdrop,
    unilevel,
  } = props;
  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const REACT_APP_USDT = (process.env.REACT_APP_USDT ?? "") as `0x${string}`; // Dirección del contrato que quieres aprobar
  const REACT_APP_RECEIVE_ACCOUNT = (process.env.REACT_APP_RECEIVE_ACCOUNT ??
    "") as `0x${string}`; // Dirección del contrato ERC20
  const [hashTRX, setHashTRX] = useState("");

  async function submit() {
    writeContract({
      address: REACT_APP_USDT, //address contrato
      abi,
      functionName: "transfer",
      args: [REACT_APP_RECEIVE_ACCOUNT, parseUnits(precio, 18)],
    });
  }

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    if (hash) {
      buyPackage(hash);
    }
  }, [hash]);

  useEffect(() => {
    if (isConfirmed) {
      console.log("Pucharse Succesfull");
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (error) {
      toast.error((error as BaseError).shortMessage || error.message);
    }
  }, [error]);

  const buyPackage = (hash: string) => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("packages_type", packagetype);
    formData.append("trx", hash);
    fetch(`${process.env.REACT_APP_BACKEND}/packages`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status === false) {
          toast.error("Tu compra, no fue valida. intentelo nuevamente");
        } else {
          toast.success(t("_MODALBUY._SUCCESS"));
          toggleTab();
        }
        updateLoading(false);
      })
      .catch((error) => {
        updateLoading(false);
        console.log(error);
        toast.error("Vuelve a intentarlo");
      });
  };

  return (
    <div className={!giro ? "carta-box" : ""}>
      <div className="carta">
        <div className="cara" style={{ backgroundColor: color }}>
          <h4 className="text-center">
            {t("_PACKAGES._PACKAGE")} {nombre}{" "}
          </h4>
          <div className="line-gradient"></div>
          <div className="title-logo">
            <svg
              width="123"
              height="124"
              viewBox="0 0 123 124"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.44922 83.61C3.44922 83.61 63.5992 99.71 57.5092 45.97C57.5092 45.97 90.3392 59.42 73.3892 98.04C73.3892 98.04 69.5892 111.13 46.8792 122C46.8792 121.99 14.9992 114.86 3.44922 83.61Z"
                fill="#276096"
              />
              <path
                d="M81.8194 68.29C81.8194 68.29 90.6294 104.58 54.6494 122.91C54.6494 122.91 65.7894 124.22 73.9794 121.8C73.9794 121.79 100.789 93.06 81.8194 68.29Z"
                fill="#347CBC"
              />
              <path
                d="M20.3297 55.13C20.3297 55.13 13.3297 66.72 0.429688 51.66C0.429688 51.66 3.95971 21.62 32.7597 6.94999C33.2197 6.70999 33.6897 6.47999 34.1797 6.24999C51.2997 -1.96001 71.2497 -1.73002 88.5297 6.14998C133.62 26.7 121.53 77.18 121.53 77.18C121.53 77.18 125.7 26.93 73.3297 21.61C73.3297 21.61 42.2597 19.63 37.7797 49.89C37.3097 53.05 35.9797 54.62 33.3097 56.37C30.3997 58.29 26.0597 59.19 20.3297 55.13Z"
                fill="url(#paint0_linear_97_46717)"
              />
              <path
                d="M83.7592 120.12C83.7592 120.12 99.0792 98.85 94.0692 73.37C91.5292 60.44 83.4092 49.14 71.8992 42.73C62.4192 37.45 49.7092 34.81 38.1992 47.08C38.1992 47.08 41.1092 17.53 77.9592 20.73C77.9592 20.73 121.559 25.3 121.529 76.44C121.529 77.04 121.479 77.64 121.379 78.24C120.539 83.31 112.039 109.91 83.7592 120.12Z"
                fill="url(#paint1_linear_97_46717)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_97_46717"
                  x1="3.22311"
                  y1="28.1258"
                  x2="125.887"
                  y2="42.551"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#337EBE" />
                  <stop offset="1" stop-color="#00FCFE" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_97_46717"
                  x1="36.092"
                  y1="65.1996"
                  x2="121.769"
                  y2="75.2752"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#3D4AA6" />
                  <stop offset="1" stop-color="#23C0E0" />
                </linearGradient>
              </defs>
            </svg>
            <h1>{nombre}</h1>
          </div>
          {giro ? (
            <h4 className="text-center">
              {balance} de {ganancia} 🌊
            </h4>
          ) : (
            <h2 className="text-center">${precio}</h2>
          )}
          {giro ? (
            status === "0" ? (
              <div className="button-confirm">Confirmando compra</div>
            ) : status === "1" ? (
              completed === "1" ? (
                <div className="button-completed">Paquete completado</div>
              ) : (
                <div className="button-progress">En progreso</div>
              )
            ) : (
              <div className="button-no-status">Estado desconocido</div>
            )
          ) : (
            <div className="button-carta">{t("_PACKAGES._BUTTON1")}</div>
          )}
          {progressValue > 0 && (
            <progress
              id="initialProgress"
              max="100"
              value={progressValue}
              style={{ width: "100%" }}
              className="pink"
            ></progress>
          )}
        </div>
        <div className="cara detras">
          <h4 className="text-center">
            {t("_PACKAGES._PACKAGE")} {nombre}{" "}
          </h4>
          <div className="line-gradient"></div>
          <div className="text-cara-detras">
            <p>
              ROI: {t("_PACKAGES._PROFIT")} {porcentaje}{" "}
            </p>
            <p>Airdrop: {airdrop}🌊</p>
            <p>Profit Diario 0.5% hasta 3% </p>
            <p>Bono directo {unilevel}% </p>
            <p>
              {planes} {t("_PACKAGES._PLANS")}
            </p>
          </div>
          <br />
          <h2>${precio} </h2>
          <button
            className="button-carta"
            onClick={() => submit()}
            disabled={isPending}
          >
            {isPending ? "Transacción en proceso" : t("_PACKAGES._BUTTON2")}
          </button>
        </div>
      </div>
    </div>
  );
}
